.modal-container.sell-modal {
  width: 500px;
  padding: 20px 20px 10px 20px;

  .modal-content {
    span,
    input,
    p,
    label {
      font-size: 14px;
      text-align: center;
    }
    label,
    input {
      text-align: left;
    }
    .smallText {
      font-size: 12px;
    }
    > div {
      width: 100%;
    }
    .amount-wrap {
      label {
        line-height: 35px;
      }
    }
    .amount-analyze {
      width: auto;
    }
    .percentage-wrap {
      label {
        line-height: 35px;
      }
    }
    a {
      width: 100%;
    }
  }
}

@media screen and (max-width: 548px) {
  .modal-container.sell-modal {
    width: 300px;
  }
}
