.setting-container {
    .setting-section {

        .btn,
        .form-control {
            height: 34px;
            border-radius: 5px !important;
        }

        .card-title {
            font-size: 14px;
            width: 100%;
            display: flex;
            align-items: center;

            svg {
                font-size: 18px;
            }
        }

        .setting {
            display: flex;
            align-items: center;
            flex-direction: column;

            .tmp-wrap {
                margin: 20px 0px 0px 0px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                &>a {
                    max-width: calc(50% - 7px);
                    flex-grow: 1;
                    margin-top: 10px;

                    .btn {
                        justify-content: space-around;
                        padding: 0px 10px;
                        // height: 28px;
                        display: block;
                        width: 100%;
                        border-radius: 20px !important;
                        &.selected {
                            transform: translate(0px, -2px);
                            border: 1px solid #000000!important;
                            box-shadow: 0px 3px 6px -2px white!important;
                        }
                    }
                }
            }

            .page-set {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                padding-top: 20px;

                &>#font-type-label {
                    font-size: 16px;
                    color: white;
                    font-family: sigmarone, supercell, icomoon, Arial, Helvetica, sans-serif;
                    padding-top: 10px;
                }

                &>span {
                    font-size: 16px;
                    color: white;
                    padding-top: 10px;
                }

                &>div {

                    // min-width: 150px;
                    .MuiButtonBase-root {
                        padding: 5px;
                    }
                }

                &>.uploader {
                    margin-left: 10px;
                    max-width: 240px;
                    width: 100%;
                    min-width: 10px;
                    border-color: var(--font-color);

                    svg {
                        path {
                            fill: var(--font-color)
                        }
                    }
                }
            }
        }

        .form-group {
            padding-bottom: 20px;
            width: 100%;

            label {
                font-size: 18px;
                margin: 8px 0px;
            }

            small {
                font-size: 16px;
                margin: 5px 0px;
            }

            a {
                .btn {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;

                    span {
                        display: flex;
                        color: white !important;
                        align-items: center;
                        padding: 0px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    // rpc-section
    .rpc-head-section {
        img {
            width: 210px;
            margin: 10px 0px;
        }

        .subtitle {}

        span {
            margin: 10px 0px;
            color: white;
        }
    }

    .chain-card {
        width: 100%;

        .content-part {
            img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }

            &>div {
                width: 100%;

                strong {
                    font-size: 18px;
                }

                small {
                    font-size: 14px;
                    margin: 10px 0px;
                }
            }

        }

        .btns-part {
            a {
                margin-left: 10px;
                button {
                    padding: 8px 15px;
                }
            }
        }

    }
    .btn-group {
        a {
            .btn {
                width: 100%;
                display: flex;
                justify-content: space-around;
            }
        }
    }
    .net-group {
        a {
            width: 100%;
        }
    }
}

.pancake-font-color {
    #font-type-label {
        font-family: icomoon, Arial, Helvetica, sans-serif !important;
    }
}