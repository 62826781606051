.swap-container {
    .swap-section {
        .logo {
            width: 200px;
            height: 200px;
        }

        .swap-card {
            .subtitle {
                text-align: left;
            }

            .tmp-group {
                display: flex;
                justify-content: space-between;
            }

            .form-control {
                margin: 4px 0px;
            }

            a {
                display: block;
                margin-top: 20px;
                width: 100%;
                .btn {
                    display: block;
                    text-align: center;
                    width: 100%;
                }
            }
        }

        .stats-card {
            display: flex;
            flex-direction: column;
            align-items: center;

            strong {
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                display: flex;
                align-items: center;
                margin: 10px 0px 20px 0px;
            }

            &>div {
                width: 100%;

                .stats-group {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        width: 40px;
                        height: 40px;
                        margin-bottom: 20px;
                    }

                    .loading {
                        filter: brightness(0%);
                    }

                    small {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.dark-mode {
    .swap-container {
        .swap-section {
            .stats-card {
                &>div {
                    .stats-group {
                        .loading {
                            filter: brightness(100%);
                        }
                    }
                }
            }
        }
    }
}