.stake-container {
  .card-title {
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: center;

    svg {
      font-size: 18px;
    }
  }

  .stake-section {
    .supply-card {
      display: flex;
      align-items: center;
      flex-direction: column;

      label {
        width: 100%;
      }

      > img {
        width: 100px;
        height: 100px;
        margin: 20px 0px;
      }

      & > small {
        font-size: 12px;
      }
      .stakerAndHolder {
        margin-bottom: 20px;
        font-size: 14px;
      }

      .sub-card-group {
        width: 100%;

        .sub-card {
          display: flex;
          width: 100px;
          flex-direction: column;
          align-items: center;

          img {
            height: 25px;
            filter: brightness(0);
            &.load {
              height: 22px;
              margin: 18px 0px;
            }
          }

          > span {
            font-size: 12px;
            margin: 20px 0px;
          }

          small {
            font-size: 11px;
          }
        }
      }

      .btn-group {
        & > a {
          width: calc(50% - 5px);
        }
      }
    }

    .btn-group {
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn {
          display: flex;
          justify-content: space-around;
          width: 100%;
          // border-radius: 20px;
          // height: 28px;

          span {
            display: flex;
            align-items: center;
            padding: 0px;

            svg {
              font-size: 16px;
              margin-right: 5px;
              display: inline;
            }

            img {
              margin-right: 5px;
              width: auto;
              height: 12px;
            }
          }
        }
      }
    }

    .stake-card {
      label {
        font-size: 14px;
      }

      small {
        font-size: 10px;
      }

      .form-control {
        margin: 10px 0px 5px 0px;
      }

      .btn-group {
        flex-wrap: wrap;
        a {
          width: calc(100% - 110px);
          min-width: 100px;

          &:last-child {
            width: 100px;
          }
        }
      }

      div.border-bottom-hr {
        margin: 10px 0px 20px 0px;
      }
    }

    .unstake-card {
      label {
        font-size: 14px;
      }

      small {
        font-size: 10px;
      }

      .form-control {
        margin: 10px 0px 5px 0px;
      }

      .btn-group {
        a {
          &:first-child {
            width: calc(100% - 110px);
            min-width: 100px;
          }

          &:last-child {
            width: 100px;
          }
        }
      }

      div.border-bottom-hr {
        margin: 10px 0px 20px 0px;
      }
    }

    .statistic {
      .statistic-card {
        margin-top: 30px;
        display: flex;
        width: 100px;
        flex-direction: column;
        align-items: center;

        img {
          height: 30px;
          filter: brightness(0);
          &.load {
            margin: 20px 0px;
            height: 20px;
          }
        }

        span {
          text-align: center;
          margin: 20px 0px;
        }

        small {
          font-size: 14px;
        }
      }
    }
  }
}

.dark-mode {
  .stake-section {
    .supply-card {
      .sub-card-group {
        .sub-card {
          img {
            filter: brightness(1);
          }
        }
      }
    }

    .statistic {
      .statistic-card {
        img {
          filter: brightness(1);
        }
      }
    }
  }
}
