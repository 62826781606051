.modal-container.transfer-modal {
  width: 500px;
  padding: 20px 20px 10px 20px;

  .modal-content {
    >* {
      width: 100%;
    }
    a {
      width: calc(50% - 5px);
    }
  }
}

@media screen and (max-width: 548px) {
  .modal-container.transfer-modal {
    width: 300px;
  }
}
