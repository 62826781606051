.modal-container.receive-modal {
  width: 400px;
  padding: 20px 10px 10px;

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 10px 0px;

    & > img {
      width: 200px;
      height: 200px;
      margin: 10px 0px;
    }

    .username {
      color: #0be881;
      font-size: 16px;
      line-height: 42px;
    }

    .address {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }

  a {
    width: 100%;
  }
}

@media screen and (max-width: 548px) {
  .modal-container.receive-modal {
    width: 300px;
  }
}
