.claim-container {
    .claim-section {
        .contentDescription {
            color: var(--font-color);
            font-size: 14px;
            text-align: center;
            margin: 40px 0px;
        }

        .card-title {
            font-size: 14px;
            width: 100%;
            display: flex;
            align-items: center;

            svg {
                font-size: 18px;
            }
        }

        .claim-card {
            img {
                width: 200px;
                height: 200px;
                margin: 20px 0px;
            }

            strong {
                font-size: 20px;
                line-height: 28px;
            }

            small {
                font-size: 16px;
                line-height: 24px;
            }

            .suttitle {
                margin: 20px 0px;
            }

            div.border-bottom-hr {
                width: 100%;
                margin: 10px 0px;
                border-bottom: 1px solid rgba(255, 255, 255, .08) !important;
            }

            &>a {
                display: block;
                width: 100%;
                margin: 10px 0px 5px 0px;

                .btn {
                    font-size: 16px !important;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}