// import initail value
@import url(./assets/css/variable.scss);

/* normalize CSS */
@font-face {
  font-family: supercell;
  src: url(../src/assets/font/supercell-fonts/supercell-magic-webfont.woff);
}

@font-face {
  font-family: sigmarone;
  src: url(../src/assets/font/sigmarone/SigmarOne-Regular.ttf);
}

@font-face {
  font-family: icomoon;
  src: url(../src/assets/font/icomoon/icomoon.woff);
}

@font-face {
  font-family: gagalin;
  src: url(../src/assets/font/gagalin/Gagalin-Regular.ttf);
}

* {
  box-sizing: border-box;
  transition: all 0.3s;
}

.sofi-font-mode {
  * {
    font-family: sigmarone, supercell, icomoon, Arial, Helvetica, sans-serif;
    // text-shadow: #d6cba660 3px 6px 0, #dac99360 -2.25px 0 0,
    //   #dac99360 0 5.25px 0, #dac99360 0 -2.25px 0, #dac99360 0.75px 0.75px 0,
    //   #dac99360 -1.5px -1.5px 0, #dac99360 0.75px -1.5px 0,
    //   #dac99360 -1.5px 0.75px 0;

    .btn.btn-success,
    .btn.btn-danger,
    .btn.btn-warning,
    .btn.btn-info,
    .btn.btn-block,
    .btn.btn-orange,
    .btn.btn-black {
      // text-shadow: #000 1px 2px 0, #000 -1.5px 0 0, #000 0 2px 0,
      //   #000 0 -1.5px 0, #000 0.3px 0.3px 0, #000 -0.8px -0.8px 0,
      //   #000 0.3px -0.8px 0, #000 -0.8px 0.3px 0;
    }
  }
}

.dark-mode.sofi-font-mode {
  * {
    // text-shadow: #000 1px 2px 0, #000 -1.5px 0 0, #000 0 2px 0, #000 0 -1.5px 0,
    //   #000 0.3px 0.3px 0, #000 -0.8px -0.8px 0, #000 0.3px -0.8px 0,
    //   #000 -0.8px 0.3px 0;
  }
}

.pancake-font-mode {
  * {
    font-family: icomoon, Arial, Helvetica, sans-serif !important;
    text-shadow: none !important;
  }
}

.gagalin-font-mode {
  * {
    font-family: gagalin, Arial, Helvetica, sans-serif !important;
  }
}

body {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: black;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input {
  outline: none;
  border: none;
}

/* Default Set CSS */
svg {
  color: var(--font-color);
}

.root,
.root > div {
  overflow: hidden;
}

.btn {
  display: flex;
  align-items: center;
  height: 35px;
  font-size: 13px;
  line-height: 13px;
  padding: 0px 8px;
  color: var(--btn-text-color);
  background-color: var(--btn-bg-color);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  transition: all 0.3s;

  & > span {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px;
    text-shadow: inherit;

    & > span {
      text-shadow: inherit;
    }

    svg {
      font-size: 16px;
      margin-right: 5px;
      display: inline;
    }

    img {
      margin-right: 5px;
      height: 12px;
      display: inline;
    }
  }

  &:hover {
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    color: var(--btn-hover-text-color);
    background-color: var(--btn-hover-bg-color);
    //   transform: scale(1.05);
  }
  &.hover-effect-ignore:hover {
    transform: scale(1);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgb(24 144 255 / 60%) !important;
    outline: 1px dotted transparent !important;
    outline-offset: 2px !important;
  }

  &.btn-success {
    background-color: #8700FF;
    border: none;
    // color: white;
    -moz-box-shadow: 02px 0 rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

    &:hover {
      background-color: #8700FFaa;
      background-image: none;
    }
  }

  &.btn-info {
    border: none;
    color: white;
    background-color: #1890ff;
    background-image: none;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

    &:hover {
      background-color: #45a3fb;
    }
  }

  &.btn-danger {
    color: black;
    background-color: white;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.6);
    }
  }

  &.btn-warning {
    background-color: #ffcf00;
    border-color: #ffcf00;
    color: white;

    &:hover {
      background-color: #ffe04d;
      border-color: #ffe04d;
    }
  }

  &.btn-block {
    background-color: rgb(99, 45, 169);
    border-color: rgb(99, 45, 169);
    color: rgba(255, 255, 255, 1);

    &:hover {
      background-color: rgb(137, 79, 214);
      border-color: rgb(82, 23, 160);
    }
  }

  &.btn-color-waring {
    color: #feb027;
  }

  &.btn-black {
    background-color: #2b2b2c;
    border-color: #2b2b2c;
    color: rgba(255, 255, 255, 1);

    &:hover {
      background-color: #464646;
      border-color: #464646;
    }
  }

  &.btn-orange {
    background-color: #d2b32a;
    border-color: #d2b32a;
    color: rgba(255, 255, 255, 1);

    &:hover {
      background-color: #ffe15ace;
      border-color: #ffe15ace;
    }
  }

  &.btn-grey {
    background-color: #4b4949;
    border-color: #4b4949;
    color: rgba(255, 255, 255, 1);

    &:hover {
      background-color: #5a5a5a;
      border-color: #5a5a5a;
    }
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 35px;
  color: var(--font-color);
  background-color: var(--btn-bg-color);
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  appearance: none;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(24 144 255 / 30%);
    border-color: rgba(0, 0, 0, 0.4);
  }
}

.card-title {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;

  svg {
    font-size: 18px;
  }
}

// ---------------------------- btn end
.subtitle {
  text-align: center;
  font-size: 4vw;
  font-weight: 400;
  margin: 10px 0px 20px 0px;
  color: var(--title-font-color);
  width: 100%;
}

.dark-mode .subtitle {
  // color: white;
  color: black;
}

.subdescription {
  font-size: 36px;
  color: black;
  text-align: center;
} 

strong {
  color: rgba(0, 0, 0, 0.8);
}

.dark-mode strong {
  color: black;
}

// ============================ layout
.mr-5 {
  margin-right: 5px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.border-top-hr {
  border-top: 1px solid rgba(255, 255, 255, 0.08) !important;
}

.border-bottom-hr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08) !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center !important;
  display: block;
  width: 100%;
}

// ============================ subtitle, subdescription end

.colouredText {
  color: #e4c039 !important;
}

.card1 {
  min-width: 200px;
  position: relative;
  padding: 25px;
  color: white;
  background-color: var(--card1-bg-color);
  border-radius: 24px;
  border: 4px solid white;
  // box-shadow: 0px 10px 20px -1px green;
  font-size: 24px;
  .subtitle {
    font-size: 36px;
  }
}

// ============================= card end

/* Default Layout */
.container-wrap {
  display: flex;
  position: relative;
}

.content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // width: calc(100vw - 260px);
  min-width: 300px;
  height: calc(100vh);
  z-index: 900;
  overflow-y: auto;
  overflow-x: hidden;
  background-image: url(../src/assets/img/wallpapers/wallpaper.png);
  background-size: 100% 100%;
  // left: 260px;
  transition: all 0.2s;
  padding: 30px 0px 10px 0px;
  padding-top: 0px;

  > div {
    width: 100%;
  }
}

// .slider-collapse {
//   // .menu-slider-wrap {
//   //   left: -260px;
//   // }

//   .content-wrap {
//     left: 0px;
//     width: 100vw;
//   }
// }

.footer-wrap {
  padding: 2px;
  // background-color: var(--bg-content-color);
  * {
    text-shadow: none !important;
  }
}

/* Scroll Custom */

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.25);
  border: 3px solid transparent;
  -moz-background-clip: content;
  -webkit-background-clip: content;
  background-clip: content-box;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dark-mode ::-webkit-scrollbar-thumb {
  // background-color: rgba(255, 255, 255, 0.25);
  background-color: #0a0a0a;
}

.dark-mode ::-webkit-scrollbar-track {
  border: 1px solid rgba(255, 255, 255, 0.07);
}

@media only screen and (max-width: 768px) {
  .content-wrap {
    left: 0px !important;
    width: 100vw;
  }
  .subtitle {
    font-size: 36px;
  }
}

@media only screen and (max-width: 548px) {
  // .subtitle {
  //   font-size: 36px;
  // }
  .subdescription {
    font-size: 30px;
  }
}
