.liquidity-container {
    .liquidity-section {
        .contentDescription {
            color: var(--font-color);
            font-size: 14px;
            text-align: center;
            margin: 40px 0px;
        }

        .btn,
        .form-control {
            height: 34px;
            border-radius: 5px !important;
        }

        .card-title {
            font-size: 14px;
            width: 100%;
            display: flex;
            align-items: center;

            svg {
                font-size: 18px;
            }
        }

        .sofi-wrap {
            display: flex;
            align-items: center;
            flex-direction: column;

            >img {
                width: 125px;
                height: 140px;
                margin-top: 40px;
            }

            .price-wrap {
                width: 100%;
                margin-bottom: 20px;

                small {
                    font-size: 12px;
                }
            }

            .tmp-wrap {
                width: 100%;

                >div {
                    width: 100%;

                    label {
                        font-size: 14px;
                        margin: 8px 0px;
                    }

                    &>a {
                        margin: 10px 0px 5px 0px;

                        .btn {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .form-group {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &>a {
                margin: 20px 0px 0px 0px;
                min-width: 130px;
                display: inline-block;
                flex-grow: 1;
                .btn {
                    display: block;
                    width: 100%;
                }
            }

            &.first {
                &>a {
                    width: 30%;
                }
            }

            &.second {
                &>a {
                    width: 49%;
                }
            }
        }
    }
}