.vaults-container {
    .vaults-section {
        .subtitle {
            text-align: left;
        }

        small {
            color: var(--font-color);
            font-size: 12px;
        }

        .card-group {
            margin: 20px 0px;
            
            .vault-card {
                margin: 10px!important;
                padding: 10px!important;
                &>img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 5px;
                    border-radius: 10px;
                }

                .btn-group {
                    width: 100%;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        .btn {
                            display: flex;
                            justify-content: space-around;
                            width: 100%;
                            background-color: rgba($color: #2B2B2C, $alpha: 1.0);
                            color: white;
                            border: 1px solid #3a3a3a;

                            span {
                                display: flex;
                                align-items: center;
                                padding: 0px;

                                img {
                                    font-size: 16px;
                                    margin-right: 5px;
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }

        .primary-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}