.rpc-container {
    .rpc-head-section {
        img {
            width: 210px;
            margin: 10px 0px;
        }

        .subtitle {}

        span {
            margin: 10px 0px;
            color: white;
        }
    }

    .chain-card {
        width: 100%;

        .content-part {
            img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }

            &>div {
                width: 100%;

                strong {
                    font-size: 18px;
                }

                small {
                    font-size: 14px;
                    margin: 10px 0px;
                }
            }

        }

        .btns-part {
            button {
                padding: 8px 15px;
                margin-left: 5px;
            }
        }
    }

    .btn-group {
        a {
            .btn {
                width: 100%;
                display: flex;
                justify-content: space-around;
            }
        }
    }
}