.armory-container {
    .armory-section {
        .armory {
            display: flex;
            align-items: center;
            flex-direction: column;

            >img {
                width: 100px;
                height: 100px;
            }

            .sub-card-group {
                width: 100%;

                .sub-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 5px 0px;
                    img {
                        height: 25px;
                        margin-bottom: 10px;
                        filter: brightness(0);
                    }

                    strong {
                        font-size: 12px;
                        margin: 5px 0px;
                    }

                    >span {
                        font-size: 12px;
                        margin: 5px 0px;
                    }

                    small {
                        font-size: 11px;
                    }
                }
            }
        }

        .btn-group {
            width: 100%;

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 50%;

                .btn {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    // border-radius: 20px;
                    // height: 28px;

                    span {
                        display: flex;
                        align-items: center;
                        padding: 0px;

                        svg {
                            font-size: 16px;
                            margin-right: 5px;
                            display: inline;
                        }
                    }
                }
            }
        }

        .deposit {
            .form-control {
                margin: 15px 0px;
            }
            .btn-group {
                a {
                    width: 33.3%;
                }
            }
        }

        .buddy {
            .tmp-group {
                margin: 15px 0px;
                .form-control {
                    width: calc(100% - 130px);
                }
                a {
                    display: block;
                    .btn {
                        padding: 0px 30px;
                        text-align: center;
                    }
                }
            }
            p {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .earn-card {
            min-width: 100px;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            strong {
                font-size: 14px;
            }
            h4 {
                margin: 20px 0px;
                font-size: 24px;
            }

        }
    }
}

.dark-mode {
    .armory-section {
        .armory {
            .sub-card-group {
                .sub-card {
                    img {
                        filter: brightness(1);
                    }
                }
            }
        }
    }
}