.reserve-container {
    .reserve-section {

        .btn-group {
            width: 100%;
            display: flex;
            justify-content: space-between;

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 50%;

                .btn {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                }
            }
        }

        .reserve-card {
            display: flex;
            align-items: center;
            flex-direction: column;

            >img {
                width: 120px;
            }

            &>.sub-title {
                font-size: 15px;
                font-weight: 400;
                margin: 20px 0px;
            }

            &>span {
                margin: 10px 0px;
                font-size: 14px;
            }

            .sub-card-group {
                width: 100%;

                .sub-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        height: 25px;
                        margin-bottom: 10px;
                        filter: brightness(0);
                    }

                    >span {
                        font-size: 12px;
                        margin: 10px 0px;
                    }

                    small {
                        font-size: 11px;
                    }
                }
            }

            .btn-group {
                a {
                    width: calc(50% - 10px);

                    .btn-success {
                        img {
                            filter: brightness(0);
                        }
                    }
                }
            }
        }

        .mint-card {
            .form-control {
                margin-top: 10px;
            }

            label {
                margin-top: 20px;
            }

            small {
                font-size: 12px;
                display: block;
                margin: 10px 0px;
            }

            .btn-group {
                margin-top: 20px;

                a {
                    width: 33.3%;

                    .btn-success {
                        color: white;
                    }
                }
            }
        }

        .treasury-card {
            display: flex;
            flex-direction: column;
            align-items: center;

            &>strong {
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                display: flex;
                align-items: center;
                margin: 10px 0px 20px 0px;
            }

            .tmp-group {
                width: 100%;
                margin-bottom: 20px;

                &>div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 20px;

                    &>img {
                        width: 40px;
                        margin: 10px 0px;

                        &.loading {
                            width: 38px;
                            filter: brightness(0);
                        }
                    }

                    &>strong {
                        font-size: 14px;
                        margin: 5px;
                    }

                    small {
                        margin: 5px;
                        font-size: 10px;
                    }
                }
            }

            &>a {
                display: block;
                width: 100%;

                button {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                }
            }
        }
    }
}

.dark-mode {
    .reserve-section {
        .reserve-card {
            .sub-card-group {
                .sub-card {
                    img {
                        filter: brightness(1);
                    }
                }
            }
        }

        .treasury-card {
            .loading {
                filter: brightness(1)!important;
            }
        }
    }
}