.staking-container {
    .staking-section {
        .logo {
            width: 200px;
            height: 200px;
        }
        .stake-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            a {
                display: block;
                width: 100%;
                img {
                    width: 100%;
                    border-radius: 10px;
                }
            }
            strong {
                color: var(--font-color);
                font-size: 14px;
                padding-top: 10px;
                text-align: center;
                height: 60px;
            }
            >div.card1 {
                width: 100%;
                min-width: 0px;
            }
        }
    }
}