.modal-wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: -100vh;
  background-color: rgba(0, 0, 0, 0.95);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;

  .modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--bg-content-color);
    border-radius: 10px;
    box-shadow: 0px 0px 20px -10px white;

    & > div {
      width: 100%;
    }

    .modal-header {
      strong {
        font-size: 20px;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0px;
      > video {
        width: 100%;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
    }
    a {
      .btn {
        padding: 0px 20px;
        width: 100%;
        justify-content: space-around;
      }
    }
  }

  &.open {
    top: 0px;
  }
}

@media screen and (max-width: 548px) {
  .modal-wrap {
    .modal-container {
      width: 70vw;
    }
  }
}
