.dashboard-container {
    .actionCard {
        width: 100%;
        display: flex;

        &.twitterCard {
            height: 100%;
            align-items: center;
        }

        img {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }

        &>div {
            width: 100%;

            strong {
                vertical-align: middle;
            }

            small {
                margin: 10px 0px;
            }

            >div {
                display: flex;
                justify-content: flex-end;

                button {
                    padding: 8px 15px;
                    margin-left: 5px;
                }
            }
        }

        a.moveIntoTweet {
            color: #188EFD;
            text-decoration: underline;
            width: 100%;
            text-align: center;
            display: block;
            padding: 20px 0px;
        }
    }

    .anaylze-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .dashboard-first {
        display: flex;
        align-items: center;
        flex-direction: column;

        .card-title {
            margin-bottom: 20px;
        }

        >img {
            width: 100px;
            height: 100px;
        }

        .sub-card-group {
            width: 100%;

            .sub-card {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 5px 0px;

                img {
                    height: 25px;
                    margin-bottom: 10px;
                    filter: brightness(0);
                }
                .load {
                    height: 18px;
                    margin: 0px;
                }

                strong {
                    font-size: 20px;
                    margin: 5px 0px;
                }

                >span {
                    font-size: 18px;
                    margin: 5px 0px;
                }
                
                small {
                    text-align: center;
                    word-wrap: break-word;
                    font-size: 14px;
                }
            }
        }
    }

    .btn-group {
        width: 100%;

        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 50%;

            .btn {
                display: flex;
                justify-content: space-around;
                width: 100%;
                // border-radius: 20px;
                // height: 28px;

                span {
                    display: flex;
                    align-items: center;
                    padding: 0px;

                    svg {
                        font-size: 16px;
                        margin-right: 5px;
                        display: inline;
                    }
                }
            }
        }
    }

    .dashboard-second {

        .card-title {
            margin-bottom: 20px;
        }

        .tmp-wrap {
            width: 100%;

            >div {
                width: 100%;

                label {
                    font-size: 14px;
                    margin: 8px 0px;
                }

                &>a {
                    margin: 10px 0px 5px 0px;

                    .btn {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}


.dark-mode {
    .dashboard-container {
        .dashboard-first {
            .sub-card-group {
                .sub-card {
                    img {
                        filter: brightness(1) !important;
                    }
                }
            }
        }
    }
}