.contentFooter {
    padding: 40px 20px;

    .left {
        color: white;

        h5 {
            font-size: 18px;
            line-height: 20px;
            font-weight: 600;
            color: white;
        }

        small {
            font-size: 16px;
            line-height: 20px;
        }

        p {
            margin-top: 10px;
            font-size: 16px;

            font {
                font-weight: 700;
                font-size: 14px;
            }
        }
    }

    .right {
        h5 {

            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            color: white;
        }

        >a {
            margin-top: 8px;
            display: block;
            font-size: 16px;
            color: white;

            &:hover {
                text-decoration: underline;
            }

            svg {
                color: white;
                font-size: 14px;
                margin-right: 5px;
            }

            img {
                filter: brightness(0);
            }

            .tele {
                border-radius: 50%;
                border: 1px solid #373737;
            }

            img {
                height: 14px;
                margin-right: 5px;
                filter: brightness(1);
                &.gitbook {
                    filter: brightness(0);
                }
            }
        }
    }
}

.dark-mode {
    .contentFooter {
        .left {
            h5 {
                color: white;
            }

            color: var(--dark-font-color);
        }

        .right {
            >div {
                width: 50%;

                h5 {
                    color: var(--dark-font-color);
                }

                >a {
                    color: rgba($color: #FFF, $alpha: 0.96);
                }
            }
            .gitbook {
                filter: brightness(1)!important;
            }
        }
    }
}