.header-wrap {
    // position: absolute;
    position: relative;
    // left: 0;
    // top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 90px;
    align-items: center;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--bg-title-color);

    &>div {
        display: flex;
        align-items: center;

        &>.btn {
            padding: 0px 8px;
            border-radius: 5px;

            &>span {
                margin-top: 3px;
                font-family: icomoon, Arial, Helvetica, sans-serif;
                text-shadow: none;
            }
        }

        .receive {
            position: relative;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #CCCCCC;
            }
            
            span {
                margin-left: 3px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: none;
            }

            .dropdown-list {
                position: absolute;
                right: 0px;
                top: 34px;
                display: none;
                flex-direction: column;
                background-color: var(--bg-content-color);
                border-radius: 5px;
                overflow: hidden;
                >button {
                    outline: none;
                    color: var(--font-color);
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px 10px;
                    height: 36px;
                    line-height: 36px;
                    border: none;
                    background-color: var(--bg-title-color);
                    border-top: 1px solid #f7f7f9;

                    >svg {
                        font-size: 20px;
                        margin-right: 10px;
                    }
                    &:first-child {
                        border-top: 0px;
                    }
                    &:hover {
                        background-color: #383838;
                    }
                }
            }
            &>:hover .dropdown-list {
                display: flex;
            }
        }

        .receive:hover .dropdown-list {
            display: flex!important;
        }


        .logo-text {
            display: flex;
            align-items: center;
            color: var(--title-font-color);
            margin-left: 20px;
            margin-right: 20px;
            font-size: 36px;
            font-weight: 600;
        }

        .MuiFormControlLabel-root {
            margin-left: 0px;
            margin-right: 0px;
        }

        .item {
            margin-left: 10px;
            display: flex;
            align-items: center;
            color: var(--font-color);
            cursor: pointer !important;

            &>span {
                cursor: pointer !important;
                margin-right: 3px;
                display: flex;
                align-items: center;

                svg {
                    font-size: 24px;
                    color: var(--title-font-color);
                    color: white;
                }
            }

            label {
                cursor: pointer !important;
                margin: 0px;
                color: var(--title-font-color);
            }
        }
    }

    .mobile-menu {
        padding: 0px 8px;
        position: absolute;
        right: 20px;
    }

    .mobile {
        display: none;
    }

    .mobile-content {
        width: calc(100% - 40px);
        position: absolute;
        top: 45px;
        background-color: var(--bg-title-color);
        padding: 0px 20px 20px 50px;

        .item {
            margin: 10px 0px;
        }

        border-radius: 0px 0px 20px 20px;
    }

    .mobile-tool-wrap {
        display: flex;
        justify-content: flex-end;

        &>div {
            display: flex;

            &>.btn {
                padding: 0px 8px;
                position: relative;

                &>a {
                    padding: 0px 8px;
                }
            }
        }
    }
}

.dark-mode {
    .receive {
        border-color: transparent !important;
    }
}

@media only screen and (max-width: 1324px) {
    .header-wrap {
        .logo-text>span {
            font-size: 30px !important;
        }

        .desktop.item label {
            font-size: 20px !important;
        }
    }
}

@media only screen and (max-width: 1174px) {
    .header-wrap {
        .logo-text>span {
            font-size: 24px !important;
        }

        .desktop {
            &>.btn {
                padding: 0px 8px;
                border-radius: 5px;
            }

            .receive {
                position: relative;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid #CCCCCC;
                }
                
                span {
                    margin-left: 3px;
                    font-family: Arial, Helvetica, sans-serif;
                    text-shadow: none;
                }
            }

            &.item {
                svg {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 982px) {
    .header-wrap {
        justify-content: space-around;

        .logo-text>span {
            font-size: 24px !important;
        }

        .desktop {
            display: none !important;
        }

        .mobile {
            display: block;
        }
    }
}

@media only screen and (max-width: 798px) {
    .header-wrap {
        .logo-text>span {
            font-size: 18px !important;
        }
    }
}