:root {
    --light-font-color: rgba(0, 0, 0);
    --light-title-font-color: rgba(0, 0, 0, 1);
    --light-bg-title-color: #c7974b3d;
    --light-bg-content-color: white;
    --light-driver-color: rgba(0, 0, 0, 0.08);
    --light-card1-bg-color: rgba(255, 255, 255, 1);
    --light-btn-text-color: rgba(0, 0, 0, 1);
    --light-btn-bg-color: rgba(255, 255, 255, 0.7);
    --light-btn-hover-text-color: rgba(0, 0, 0, 0.8);
    --light-btn-hover-bg-color: rgba(0, 0, 0, 0.04);

    --dark-font-color: rgba(255, 255, 255, 0.8);
    --dark-title-font-color: rgba(255, 255, 255, 1);
    --dark-bg-title-color: #0a0a0a;
    --dark-bg-content-color: #191C20;
    --dark-driver-color: rgba(255, 255, 255, .05);
    --dark-card1-bg-color: #111417e6;
    --dark-btn-text-color: rgba(255, 255, 255, 1);
    --dark-btn-bg-color: rgba(255, 255, 255, .1);
    --dark-btn-hover-text-color: rgba(255, 255, 255, 0.8);
    --dark-btn-hover-bg-color: rgba(255, 255, 255, 0.2);
}

:root {
    --title-font-color: var(--light-title-font-color);
    --font-color: var(--light-font-color);
    --bg-title-color: var(--light-bg-title-color);
    --bg-content-color: var(--light-bg-content-color);
    --driver-color: var(--light-driver-color);
    --card1-bg-color: var(--light-card1-bg-color);
    --btn-text-color: var(--light-btn-text-color);
    --btn-bg-color: var(--light-btn-bg-color);
    --btn-hover-text-color: var(--light-btn-hover-text-color);
    --btn-hover-bg-color: var(--light-btn-hover-bg-color);
}

.dark-mode {
    --title-font-color: var(--dark-title-font-color);
    --font-color: var(--dark-font-color);
    --bg-title-color: var(--dark-bg-title-color);
    --bg-content-color: var(--dark-bg-content-color);
    --driver-color: var(--dark-driver-color);
    --card1-bg-color: var(--dark-card1-bg-color);
    --btn-text-color: var(--dark-btn-text-color);
    --btn-bg-color: var(--dark-btn-bg-color);
    --btn-hover-text-color: var(--dark-btn-hover-text-color);
    --btn-hover-bg-color: var(--dark-btn-hover-bg-color);
}