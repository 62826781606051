.home-container {
  padding: 20px 0px;

  .first-section {
    padding: 30px 10px 50px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    img {
      width: 360px;
      height: 360px;
      margin-bottom: 20px;
    }
    small {
      margin-bottom: 20px;
    }
    & > div > div > div {
      width: 100%;

      > a {
        width: calc(50% - 50px);

        .btn {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .special-section {
    padding: 100px 10px 100px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;

    .card1 {
      width: 100%;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 30px;
        font-size: 14px;
      }
    }
    img {
      width: 330px;
      height: auto;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 900px) {
        width: 70%;
        margin: 30px 15% 0;
      }
      @media only screen and (max-width: 500px) {
        width: 100%;
        margin: 0;
      }
    }
    .image-order {
      order: 0;
      @media only screen and (max-width: 900px) {
        order: 3;
      }
    }
  }

  .second-section {
    padding: 50px 10px 50px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    .card1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
      }

      p {
        text-align: center;
        font-size: 18px;
      }
    }
  }

  .third-section {
    padding: 50px 10px 0px 10px;
  }
}

@media only screen and (max-width: 856px) {
  .home-container {
    .first-section {
      & > div > div > div > a {
        width: 100%;
      }
    }
  }
}
