.modal-container.video {
  padding: 20px 10px 10px 10px;

  a {
    .btn {
      padding: 0px 20px;
      width: 100%;
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 548px) {
  .modal-container.video {
    width: 70vw;
  }
}
