.vault-container {
    .vault-section {
        .vault-card {
            display: flex;
            align-items: center;
            flex-direction: column;

            &>img {
                width: 100px;
                height: 100px;
                margin: 20px 0px;
                filter: brightness(0);
            }

            &>.sub-title {
                font-size: 14px;
                line-height: 30px;
            }

            &>span {
                font-size: 12px;
                line-height: 30px;
                margin-bottom: 20px;
            }

            .sub-card-group {
                width: 100%;

                .sub-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        height: 25px;
                        filter: brightness(0);
                    }

                    >span {
                        font-size: 12px;
                        margin: 20px 0px;
                    }

                    small {
                        font-size: 11px;
                    }
                }
            }

            .btn-group {
                a:first-of-type .btn {
                    color: white;
                }

                a:last-of-type .btn {
                    // color: #FD392F;
                    color: white;
                }
            }
        }

        .btn-group {
            width: 100%;

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 50%;

                .btn {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;

                    span {
                        display: flex;
                        align-items: center;
                        padding: 0px;

                        img {
                            font-size: 16px;
                            margin-right: 5px;
                            display: inline;
                        }
                    }
                }
            }
        }

        .deposit {
            .form-control {
                margin: 15px 0px;
            }

            &>span {
                font-size: 14px;
            }

            .btn-group {
                &>span {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                a {
                    width: 100%;
                }

                &>a:last-of-type {
                    margin-top: 10px;
                }
            }
        }

        .withdraw {
            .form-control {
                margin: 15px 0px;
            }

            &>span {
                font-size: 14px;
            }

            .btn-group {
                &>span {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                a {
                    width: 100%;
                }

                &>a:last-of-type {
                    margin-top: 10px;
                }
            }
        }

        .transfer-card {
            &>div:first-of-type {
                margin-top: 20px;

                >div {
                    display: flex;
                    flex-direction: column;

                    >span {
                        font-size: 14px;
                        line-height: 24px;
                    }

                    .form-control {
                        width: 100%;
                    }
                }

                &>div:first-of-type {
                    width: calc(30% - 5px);
                }

                &>div:last-of-type {
                    width: 70%;
                }
            }

            &>div:last-of-type {
                &>span {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    margin: 10px 0px;
                    font-size: 14px;

                    img {
                        height: 25px;
                        margin: 0px 10px;
                    }
                }

                &>a {
                    width: 100% !important;

                    >button {
                        display: flex;
                        justify-content: space-around;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        .stats-card {
            .list-wrap {
                margin-top: 20px;

                strong {
                    font-size: 14px;
                }

                >div {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 15px;
                    }
                }
            }
        }

        .information-card {
            .list-wrap {
                margin-top: 20px;

                strong {
                    font-size: 14px;
                }

                >div {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        width: 15px;
                    }
                }
            }
        }
    }
}

.dark-mode {
    .vault-section {
        .vault-card {
            &>img {
                filter: brightness(1);
            }
            .sub-card-group {
                .sub-card {
                    img {
                        filter: brightness(1);
                    }
                }
            }
        }
    }
}