.wrongNetwork-container {
    img {
        width: 245px;
        height: 205px;
        margin: 20px 0;
    }
    h3 {
        margin: 30px 0px 20px 0px;
        &.error {
            color: #FE443B;
        }
    }
    a {
        margin-top: 20px;
        display: block;
        width: 100%;
        .btn {
            width: 100%;
            display: block;
        }
    }
}