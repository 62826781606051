.profile-container {
    width: 100%;
    .profile-section {
        .contentDescription {
            color: var(--font-color);
            font-size: 14px;
            text-align: center;
            margin: 40px 0px;
        }

        .btn,
        .form-control {
            height: 34px;
            border-radius: 5px !important;
        }

        .card-title {
            font-size: 14px;
            width: 100%;
            display: flex;
            align-items: center;

            svg {
                font-size: 18px;
            }
        }

        .name {
            display: flex;
            align-items: center;
            flex-direction: column;

            .sofi-name {
                font-size: 14px;
            }

            >img {
                width: 150px;
                height: 150px;
            }

            .sofi-name {
                display: flex;
                align-items: center;

                img {
                    margin-left: 10px;
                }

                margin-bottom: 10px;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
            }

            &>a {
                width: 100%;
                display: block;
                margin-top: 10px;

                .btn {
                    width: 100%;
                    justify-content: space-around;
                }
            }

            .form-group {
                margin-top: 20px;
                width: 100%;

                label {
                    font-size: 14px;
                    margin: 8px 0px;
                }

                small {
                    font-size: 12px;
                    margin: 5px;
                }
            }
        }

        .btn-group {
            width: 100%;
            display: flex;
            justify-content: space-between;

            a {
                &:first-child {
                    width: 49.7%;
                }

                &:last-child {
                    width: 49.7%;
                }

                .btn {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;

                    span {
                        display: flex;
                        align-items: center;
                        padding: 0px;

                        svg {
                            font-size: 16px;
                            margin-right: 5px;
                            display: inline;
                        }
                    }
                }
            }
        }

        .find-name {
            .form-group {
                margin-top: 20px;
                width: 100%;

                label {
                    font-size: 14px;
                    margin: 8px 0px;
                }

                small {
                    font-size: 12px;
                    margin: 5px 0px;
                }

                &>a {
                    margin: 10px 0px 5px 0px;

                    .btn {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }

        .find-name2 {
            display: flex;
            flex-direction: column;

            label {
                font-size: 18px;
                margin: 8px 0px;
            }

            small {
                font-size: 16px;
                margin: 5px 0px;
            }

            &>a {
                margin-top: 10px;

                .btn {
                    display: block;
                    width: 100%;
                }
            }
        }

        .right-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}