.stats-container {
    .stats-section {
        .contentDescription {
            color: var(--font-color);
            font-size: 14px;
            text-align: center;
            margin: 40px 0px;
        }
        .btn,
        .form-control {
            height: 34px;
            border-radius: 5px !important;
        }

        .card-title {
            font-size: 14px;
            width: 100%;
            display: flex;
            align-items: center;
            svg {
                font-size: 18px;
            }
        }

        .sub-card-group {
            width: 100%;

            .sub-card {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    height: 20px;
                    filter: brightness(0);
                }

                >span {
                    font-size: 12px;
                    margin: 15px 0px;
                    color: #23BF3F;
                }

                small {
                    font-size: 11px;
                }
            }
        }

        .lookup {
            display: flex;
            align-items: center;
            flex-direction: column;


            >img {
                width: 220px;
                height: 130px;
                margin-bottom: 20px;
            }


            .form-group {
                margin-top: 20px;
                width: 100%;

                label {
                    font-size: 14px;
                    margin: 8px 0px;
                }

                small {
                    font-size: 12px;
                    margin: 5px;
                }
            }
        }

        .btn-group {
            width: 100%;
            display: flex;
            justify-content: space-between;

            a {
                &:first-child {
                    width: 60%;
                }

                &:last-child {
                    width: 39.5%;
                }

                .btn {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;

                    span {
                        display: flex;
                        align-items: center;
                        padding: 0px;

                        svg {
                            font-size: 16px;
                            margin-right: 5px;
                            display: inline;
                        }
                    }
                }
            }
        }

        .data {
            .list-group {
                display: list-item;
                margin-top: 10px;
                margin-bottom: 10px;

                li {
                    margin-bottom: 10px;
                    background-color: var(--bg-content-color);
                    list-style: circle inside;
                    font-size: 12px;
                    line-height: 20px;
                }
            }

            .form-group {
                margin-top: 20px;
                width: 100%;

                label {
                    font-size: 14px;
                    margin: 8px 0px;
                }

                small {
                    font-size: 12px;
                    margin: 5px;
                }
            }
        }

        .team {
            .sub-card-group {
                margin-top: 20px;
                .sub-card {
                    >strong {
                        font-size: 14px;
                        margin: 20px;
                    }
                }
            }
            &>a {
                .btn {
                    display: block;
                    width: 100%;
                }
            }
        }

        .contract {
            .sub-card-group {
                .sub-card {
                    img {
                        margin-top: 30px;
                        margin-bottom: 20px;
                        width: 25px;
                        height: 25px;
                    }
                    small {
                        margin: 20px 0px;
                    }
                }
            }
        }
    }
}

.dark-mode {
    .stats-section {
        .sub-card-group {
            .sub-card {
                img {
                    filter: brightness(1);
                }
            }
        }
    }
}